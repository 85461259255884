<!--
 * @FileDescription: 
 * @Author: jianguo Wang
 * @Date: 2023-12-14 15:29:31
 * @LastEditors: jianguo Wang
 * @LastEditTime: 2024-10-09 17:33:34
-->
<template>
  <div class="page-contain">
    <div class="title">与客户一起构建数字生态</div>
    <div class="blank-contain">
      <div class="blanks">
        <div class="tip1">Web3</div>
        <div class="tip2">Web3 通过 ByberPay 与传统银行业务连接，将资产转化为支付。</div>
        <!-- <div class="btn">阅读更多</div> -->
      </div>
      <div class="blanks">
        <div class="tip1">支付中介</div>
        <div class="tip2">支付中介可在全球范围内扩展，而不会增加管理成本。</div>
        <!-- <div class="btn">阅读更多</div> -->
      </div>
      <div class="blanks">
        <div class="tip1">数字营销</div>
        <div class="tip2">快速扩大规模并以最高的运营效率管理客户的账户。</div>
        <!-- <div class="btn">阅读更多</div> -->
      </div>
    </div>
    <div class="blank-contain">
      <div class="blanks-second">
        <div class="blanks-second-left"><img src="@/assets/w@2x.png" alt="" /></div>
        <div class="second-right">
          <div class="second-title">私人的</div>
          <div class="second-tip">ByberPay 会保护您的个人信息不被商家发现。</div>
        </div>
      </div>
      <div class="blanks-second">
        <div class="blanks-second-left"><img src="@/assets/w2@2x.png" alt="" /></div>
        <div class="second-right">
          <div class="second-title">安全的</div>
          <div class="second-tip">所有数据均经过加密，ByberPay 不是托管服务。</div>
        </div>
      </div>
      <div class="blanks-second last-second">
        <div class="blanks-second-left"><img src="@/assets/w3@2x.png" alt="" /></div>
        <div class="second-right">
          <div class="second-title">高效的</div>
          <div class="second-tip">充值和交易实时且便捷。</div>
        </div>
      </div>
    </div>
    <div class="download-contain">
      <img class="bg-img" src="@/assets/05Bg.png" alt="" />
      <div class="info-contain">
        <div class="info-title">ByberPay卡</div>
        <div class="info-tip">「加密资产」 与「法币资产」无缝连接；支持全球在线支付、线下消费</div>
        <div class="info-btn">
          <!-- <el-popover placement="bottom" title="" width="120" trigger="hover" content="" @show="acitve = 1" :disabled="env != 'production'">
            <div slot="reference" class="right-contain-tip3" :class="acitve != 1 ? 'hover1' : ''" @click="toDownload(2)">
              <img v-if="acitve == 1" src="@/assets/ios-icon02.png" class="btn-icon" alt="" />
              <img v-else src="@/assets/ios-icon01.png" class="btn-icon" alt="" />
              <div class="ios-btn">
                <div class="ios-title1">Download on the</div>
                <div class="ios-title2">App Store</div>
              </div>
            </div>
            <img style="width: 150px; height: 150px" src="@/assets/code-ios.png" alt="" />
          </el-popover> -->
          <!-- <el-popover placement="bottom" title="" width="120" trigger="hover" content="" @show="acitve = 2" :disabled="env != 'production'">
            <div slot="reference" class="right-contain-tip4 and-btn" :class="acitve == 2 ? 'hover2' : ''" @click="toDownload(1)">
              <img src="../assets/goo@2x.png" alt="" />
              <div class="btn-text">
                <div class="ios-tip01">GET IT ON</div>
                <div class="ios-tip02">GOOGLE PLAY</div>
              </div>
            </div>
            <img style="width: 150px; height: 150px" src="@/assets/code-android.png" alt="" />
          </el-popover> -->
          <el-popover placement="bottom" title="" trigger="hover" content="" @show="acitve = 3" popper-class="poppers" :disabled="env != 'production'">
            <div slot="reference" class="right-contain-tip4" style="margin-left: 16px" :class="acitve == 3 ? 'hover2' : ''" @click="toDownload(3)">
              <!-- <img v-if="acitve == 1" src="@/assets/anz-icon01.png" class="btn-icon" alt="" />
                <img v-else src="@/assets/anz-icon02.png" class="btn-icon" alt="" /> -->
              <img v-if="acitve == 3" src="../assets/down.png" class="btn-icon" alt="" />
              <img v-else src="../assets/down2.png" class="btn-icon" alt="" />
              安装APK
            </div>
            <img style="width: 150px; height: 150px" src="@/assets/apk.png" alt="" />
          </el-popover>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "thirdPart",
  props: {
    msg: String,
  },
  data() {
    return {
      acitve: 1,
      env: process.env.NODE_ENV,
    };
  },
  methods: {
    toDownload(type) {
      if (type == 1) {
        // 谷歌
        if (process.env.NODE_ENV == "production") location.href = "https://play.google.com/store/apps/details?id=com.bytelink.cregispay.card";
      } else if (type == 2) {
        // 苹果
        if (process.env.NODE_ENV == "production") location.href = "https://apps.apple.com/hk/app/byberpay/id6572298569";
      } else {
        if (process.env.NODE_ENV == "production") {
          location.href = "https://file.byberpay.org/app/ByberPay.apk";
        } else {
          location.href = "https://file.byberpay.cc/app/ByberPay.apk";
        }
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./thirdPartCss/max.css" scoped></style>
<style src="./thirdPartCss/middle.css" scoped></style>
<style src="./thirdPartCss/flat.css" scoped></style>
<style src="./thirdPartCss/min.css" scoped></style>
<style scoped></style>
