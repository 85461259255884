import { render, staticRenderFns } from "./footer.vue?vue&type=template&id=01176557&scoped=true"
import script from "./footer.vue?vue&type=script&lang=js"
export * from "./footer.vue?vue&type=script&lang=js"
import style0 from "./footerCss/max.css?vue&type=style&index=0&id=01176557&prod&scoped=true&lang=css&external"
import style1 from "./footerCss/middle.css?vue&type=style&index=1&id=01176557&prod&scoped=true&lang=css&external"
import style2 from "./footerCss/flat.css?vue&type=style&index=2&id=01176557&prod&scoped=true&lang=css&external"
import style3 from "./footerCss/min.css?vue&type=style&index=3&id=01176557&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01176557",
  null
  
)

export default component.exports