<!--
 * @FileDescription: 
 * @Author: jianguo Wang
 * @Date: 2023-12-14 15:29:31
 * @LastEditors: jianguo Wang
 * @LastEditTime: 2023-12-28 10:15:50
-->
<template>
  <div class="page-contain">
    <img src="@/assets/logo@3x.png" alt="" />
    <div class="footer-contain">
      <span class="footer-tip">©ByberPay. All rights reserved.</span>
      <span class="footer-tip" @click="toyingsi" style="cursor: pointer">隐私政策</span>
      <span class="footer-tip">条款和条件</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "headerPart",
  props: {
    msg: String,
  },
  methods: {
    toyingsi() {
      this.$router.push({ name: "agreement" });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./footerCss/max.css" scoped></style>
<style src="./footerCss/middle.css" scoped></style>
<style src="./footerCss/flat.css" scoped></style>
<style src="./footerCss/min.css" scoped></style>
<style scoped></style>
