import Vue from "vue";
import Router from "vue-router";
import Index from "./pages/index.vue";
import agreement from "./pages/agreement.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "home",
      component: Index,
    },
    {
      path: "/agreement",
      name: "agreement",
      component: agreement,
    },
  ],
});
